import { Alert, auth } from "@project/shared";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Login } from "../../components/organism/Login";
import { FetchUserToken } from "../../services/user";
import RestrictedRoute from "../../withRestrictedRoute";
import styled from "styled-components";
import { Spin } from "antd";
import { AuthContext } from "../../utils";
import { useTranslation } from "react-i18next";

const StyledSpinWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
const ComapnyLogin = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);
  const { data: token, isLoading, isFetching } = useQuery(
    "fetchToken",
    () => FetchUserToken(router?.query?.id),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: router?.query?.id ? true : false,
      onError: (err: any) => {
        Alert({
          type: "error",
          message: t("Error occured"),
          description: t(err.data.error.message),
        });
      },
    }
  );

  const fetchCustomToken = async (token) => {
    try {
      await auth().signOut();
      setUser(null);
      await auth().setPersistence(auth.Auth.Persistence.SESSION);
      await auth().signInWithCustomToken(token);
    } catch (err) {
      // console.log("err", err)
    }
  };

  useEffect(() => {
    if (token?.data?.customToken) {
      fetchCustomToken(token?.data?.customToken);
    }
  }, [token]);
  return (
    <div>
      {isLoading || isFetching ? (
        <StyledSpinWrapper>
          <Spin />
        </StyledSpinWrapper>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default RestrictedRoute(ComapnyLogin);
