import { getUsersID } from "@project/shared";

export const getCompDirection = (
  messageData,
  currentCompanyID: string
): string =>
  messageData.sender_id !== messageData.receiver_id
    ? currentCompanyID === messageData.sender_id
      ? "outgoing"
      : "incoming"
    : messageData.sender_role == "company"
    ? "outgoing"
    : "incoming";

export const getInboxCompDirection = (
  messageData,
  currentCompanyID: string
): string => {
  const { receiverId, senderId } = getUsersID(
    messageData.lastMessageSender,
    messageData.participantMember,
    messageData.participantCompany
  );
  return receiverId !== senderId
    ? currentCompanyID === senderId
      ? "outgoing"
      : "incoming"
    : messageData.senderRole == "company"
    ? "outgoing"
    : "incoming";
};
