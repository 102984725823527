import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../../assets/logo.png";
import { Button, Form, Input } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Alert, auth, theme } from "@project/shared";

import { useRouter } from "next/router";
import Link from "next/link";
import { useMutation } from "react-query";
import {
  CheckUserInfo,
  getUserDetails,
  ChangeUserEmail,
} from "../../../services/user";

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 86px;
`;
const FormItemWrapper = styled.div`
  height: 96px;
`;
const Container = styled.div`
  max-width: 314px;
`;
const StyledForm = styled(Form)`
  margin-top: 24px;
`;
const Label = styled.span`
  color: ${theme.adminTextFieldColor};
  line-height: 22px;
`;
const StyledFormItem = styled.div`
  display: flex;
  justify-content: center;
  & .ant-btn {
    min-width: 96px;
    line-height: 24px;
  }
`;
const Error = styled.span`
  font-size: 12px;
  color: ${theme.errorText};
`;
const ForgotPasswordDiv = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const Login = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const { mutate } = useMutation(CheckUserInfo, {
    onSuccess: () => {
      handleUserLogin();
    },
    onError: () => {
      Alert({
        type: "error",
        message: t("Error occured"),
        description: t(
          "Could not log in. Please check your account information."
        ),
      });
    },
  });
  const validationSchema = yup.object().shape({
    email: yup.string().required(t("Please enter")).email(t("Invalid Email")),
    password: yup.string().required(t("Please enter")),
    company_id: yup.string().required(t("Please enter")),
  });

  const handleUserLogin = async () => {
    setLoading(true);
    try {
      await auth().signInWithEmailAndPassword(
        formik.values.email,
        formik.values.password
      );
      Alert({
        type: "success",
        message: t("Success"),
        description: t("Signed in successfully"),
      });
      checkIfEmailChanged(auth().currentUser.uid);
      router.push("/jobs");
    } catch (error) {
      const errorCode = error.code;

      if (
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        Alert({
          type: "error",
          message: t("Error occured"),
          description: t(
            "Could not log in. Please check your account information."
          ),
        });
      } else if (errorCode === "auth/too-many-requests") {
        Alert({
          type: "warning",
          message: t(
            "The maximum number of password attempts has been reached. Please try again in a few minutes."
          ),
        });
      } else {
        Alert({
          type: "error",
          message: t("Something went wrong"),
          description: t(
            "Could not log in. Please check your account information."
          ),
        });
      }
    }
    setLoading(false);
  };

  const { mutate: checkIfEmailChanged } = useMutation(getUserDetails, {
    onSuccess: (response) => {
      if (response.email && response.email != auth().currentUser.email) {
        ChangeEmail();
      }
    },
  });

  const { mutate: ChangeEmail } = useMutation("change-email", () =>
    ChangeUserEmail({
      email: auth().currentUser.email,
      id: formik.values.company_id,
    })
  );

  const handleLoginFormSubmit = async () => {
    mutate({ email: formik.values.email, companyID: formik.values.company_id });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      company_id: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  });

  return (
    <MainWrapper>
      <Container>
        <img src={Logo} alt="SideBizz" />
        <StyledForm onFinish={formik.handleSubmit} layout="vertical">
          <FormItemWrapper>
            <Form.Item label={<Label>{t("Company ID")}</Label>}>
              <Input
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="company_id"
                size="large"
                type="text"
              />
              {formik.touched.company_id && formik.errors.company_id && (
                <Error>{formik.errors.company_id}</Error>
              )}
            </Form.Item>
          </FormItemWrapper>
          <FormItemWrapper>
            <Form.Item label={<Label>{t("Email")}</Label>}>
              <Input
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="email"
                size="large"
                type="email"
              />
              {formik.touched.email && formik.errors.email && (
                <Error>{formik.errors.email}</Error>
              )}
            </Form.Item>
          </FormItemWrapper>
          <FormItemWrapper>
            <Form.Item label={<Label>{t("Password")}</Label>}>
              <Input
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="password"
                size={"large"}
                type="password"
              />
              {formik.touched.password && formik.errors.password && (
                <Error>{formik.errors.password}</Error>
              )}
            </Form.Item>
          </FormItemWrapper>
          <StyledFormItem>
            <Button
              htmlType="submit"
              loading={loading}
              size="large"
              type="primary"
            >
              {t("Login")}
            </Button>
          </StyledFormItem>
          <ForgotPasswordDiv>
            <Link href="/forgot-password">
              <a>{t("Forgot password?")}</a>
            </Link>
          </ForgotPasswordDiv>
        </StyledForm>
      </Container>
    </MainWrapper>
  );
};

export { Login };
